import debounce from '@tinkoff/utils/function/debounce';
import {createListenerMiddleware} from '@reduxjs/toolkit';

import WebSocketClient from 'client/web_websocket_client';
import {PostTypes} from 'mattermost-redux/action_types';
import {getConfig} from 'mattermost-redux/selectors/entities/general';
import {getCurrentChannelId} from 'mattermost-redux/selectors/entities/common';
import {getIsRhsSuppressed, getSelectedPostId} from 'selectors/rhs';
import {ActionTypes, Constants} from 'utils/constants';

import {selectChannel} from 'features/channels/actions/select_channel';
import {removeThread} from 'features/threads/actions/remove_thread';
import {getSelectedThreadId} from 'features/threads/selectors/get_selected_thread_id';
import {setSelectedThreadId} from 'features/threads/actions/set_selected_thread_id';
import {setSelectedTab} from 'features/threads/actions/set_selected_tab';
import {removeTeamThreads} from 'features/threads/actions/remove_team_threads';

import type {GlobalState} from 'types/store';
import type {AppDispatch} from 'stores/redux_store';

const updatePresenceListener = createListenerMiddleware<GlobalState, AppDispatch>();

const TYPES_AFFECTING_SELECTED_POST_ID = [
    ActionTypes.SELECT_POST,
    ActionTypes.SELECT_POST_CARD,
    PostTypes.POST_REMOVED,
    ActionTypes.UPDATE_RHS_STATE,
    ActionTypes.RHS_GO_BACK,
];

const TYPES_AFFECTING_SELECTED_CHANNEL_ID = [selectChannel.type];

const TYPES_AFFECTING_SELECTED_THREAD_ID = [
    setSelectedThreadId.type,
    setSelectedTab.type,
    removeTeamThreads.type,
    removeThread.type,
];

const ACTION_TYPES_TO_REACT_TO = new Set<string>([
    ...TYPES_AFFECTING_SELECTED_POST_ID,
    ...TYPES_AFFECTING_SELECTED_CHANNEL_ID,
    ...TYPES_AFFECTING_SELECTED_THREAD_ID,
]);

let prevChannelId = '';
let prevThreadId = '';

const updatePresenceDebounced = debounce(Constants.UPDATE_PRESENCE_DEBOUNCE_TIMEOUT, (state: GlobalState) => {
    const channelId = getCurrentChannelId(state);
    const globalThreadId = getSelectedThreadId(state);
    const rhsThreadId = getIsRhsSuppressed(state) ? '' : getSelectedPostId(state);
    const threadId = rhsThreadId || globalThreadId;

    if (prevChannelId !== channelId || prevThreadId !== threadId) {
        prevChannelId = channelId;
        prevThreadId = threadId;

        WebSocketClient.updatePresence({channelId, threadId});
    }
});

updatePresenceListener.startListening({
    predicate: (action, state) => {
        const config = getConfig(state);

        if (config.FeatureFlagWebSocketTypingEventScoping !== 'true') {
            return false;
        }

        return ACTION_TYPES_TO_REACT_TO.has(action.type);
    },
    effect: (_, store) => {
        updatePresenceDebounced(store.getState());
    },
});

export function clearUpdatePresenceCache() {
    prevChannelId = '';
    prevThreadId = '';
}

export const updatePresenceListenerMiddleware = updatePresenceListener.middleware;
