import omit from '@tinkoff/utils/object/omit';

import {addQuery} from '@tinkoff/url';

import {Client4} from 'mattermost-redux/client';

import {
    VERSION_REQUEST_RETRIES,
    VERSION_REQUEST_RETRY_DELAY,
    VERSION_REQUEST_TIMEOUT,
} from './constants';

type VersionResponse = {version: string};

export async function getWebappVersion() {
    const staticPath = __webpack_public_path__.split('/').filter(Boolean).join('/');
    const options = omit(['url', 'method'], Client4.getTimeApiClientOptions({}));

    const timestampToSkipCache = Date.now().toString();

    const url = addQuery(`/${staticPath}/version.json`, {
        cacheBust: timestampToSkipCache,
    });

    const {data} = await Client4.requester.get<VersionResponse>(url.toString(), {
        ...options,
        'axios-retry': {
            retries: VERSION_REQUEST_RETRIES,
            retryDelay: () => VERSION_REQUEST_RETRY_DELAY,
        },
        timeout: VERSION_REQUEST_TIMEOUT,
    });

    return data.version;
}
