import {useDialog} from '@react-aria/dialog';
import {useRef, type ReactNode} from 'react';

type Props = {
    children: ReactNode;
}

export const DialogOverlay = ({children}: Props) => {
    const ref = useRef(null);
    const {dialogProps} = useDialog({}, ref);

    return <div ref={ref} {...dialogProps}>{children}</div>;
};
