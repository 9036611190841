import {useRef, type ReactNode} from 'react';

import classNames from 'classnames';

import {useTooltipTriggerState} from '@react-stately/tooltip';

import {useTooltipTrigger} from '@react-aria/tooltip';

import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';
import {Tooltip} from '@time-webkit/all/atoms/tooltip';

import styles from './update_button.module.css';

type Props = {
    text: string;
    icon?: ReactNode;
    tooltip?: string;
    className?: string;
    onClick: () => void;
};

export const UpdateButton = ({text, icon, tooltip, className, onClick}: Props) => {
    const bodyMTrue = useTypography({
        size: TypographySize.BodyMTrue,
        noDefaultColor: true,
    });
    const targetRef = useRef(null);
    const tooltipTriggerState = useTooltipTriggerState({delay: 500});
    const {triggerProps, tooltipProps} = useTooltipTrigger({}, tooltipTriggerState, targetRef);

    return (
        <>
            <button
                {...triggerProps}
                className={classNames(styles.button, className)}
                ref={targetRef}
                onClick={onClick}
            >
                {icon}
                <span className={classNames(bodyMTrue, styles.text)}>{text}</span>
            </button>
            {tooltip && tooltipTriggerState.isOpen && (
                <Tooltip
                    {...tooltipProps}
                    state={tooltipTriggerState}
                    placement='bottom'
                    offset={4}
                    targetRef={targetRef}
                >
                    {tooltip}
                </Tooltip>
            )}
        </>
    );
};
