/* eslint-disable no-mixed-operators */
import {
    AUDIO_TYPES,
    CODE_TYPES,
    FILE_TYPES,
    IMAGE_TYPES,
    PATCH_TYPES,
    PDF_TYPES,
    PRESENTATION_TYPES,
    SPREADSHEET_TYPES,
    SVG_TYPES,
    TEXT_TYPES,
    VIDEO_TYPES,
    WORD_TYPES,
    MAX_FILENAME_LENGTH,
} from './constants';

const TYPES_MAP = new Map([
    [TEXT_TYPES, FILE_TYPES.TEXT],
    [IMAGE_TYPES, FILE_TYPES.IMAGE],
    [AUDIO_TYPES, FILE_TYPES.AUDIO],
    [VIDEO_TYPES, FILE_TYPES.VIDEO],
    [SPREADSHEET_TYPES, FILE_TYPES.SPREADSHEET],
    [CODE_TYPES, FILE_TYPES.CODE],
    [WORD_TYPES, FILE_TYPES.WORD],
    [PRESENTATION_TYPES, FILE_TYPES.PRESENTATION],
    [PDF_TYPES, FILE_TYPES.PDF],
    [PATCH_TYPES, FILE_TYPES.PATCH],
    [SVG_TYPES, FILE_TYPES.SVG],
]);

const removeQuerystringOrHash = (extension: string) => {
    return extension.split(/[?#]/)[0];
};

export const getFileType = (extension: string): string => {
    const ext = removeQuerystringOrHash(extension.toLowerCase()) || '';
    const key = Array.from(TYPES_MAP.keys()).find((types) => types.indexOf(ext) > -1);

    return (key && TYPES_MAP.get(key)) || FILE_TYPES.OTHER;
};

export function fileSizeToString(bytes: number, separator = ' '): string {
    if (bytes > 1024 ** 4) {
        if (bytes < 1024 ** 4 * 10) {
            return `${Math.round((bytes / 1024 ** 4) * 10) / 10}${separator}TB`;
        }

        return `${Math.round(bytes / 1024 ** 4)}${separator}TB`;
    }

    if (bytes > 1024 ** 3) {
        if (bytes < 1024 ** 3 * 10) {
            return `${Math.round((bytes / 1024 ** 3) * 10) / 10}${separator}GB`;
        }

        return `${Math.round(bytes / 1024 ** 3)}${separator}GB`;
    }

    if (bytes > 1024 ** 2) {
        if (bytes < 1024 ** 2 * 10) {
            return `${Math.round((bytes / 1024 ** 2) * 10) / 10}${separator}MB`;
        }

        return `${Math.round(bytes / 1024 ** 2)}${separator}MB`;
    }

    if (bytes > 1024) {
        return `${Math.round(bytes / 1024)}${separator}KB`;
    }

    return `${bytes}B`;
}

export function trimFilename(filename: string, maxFileNameLength = MAX_FILENAME_LENGTH) {
    let trimmedFilename = filename;

    if (filename.length > maxFileNameLength) {
        trimmedFilename = `${filename.substring(0, Math.min(maxFileNameLength, filename.length))}...`;
    }

    return trimmedFilename;
}
