import {createListenerMiddleware} from '@reduxjs/toolkit';

import {selectChannel} from 'features/channels/actions/select_channel';
import {getSelectedThreadId} from 'features/threads/selectors/get_selected_thread_id';
import {getAllChannels} from 'mattermost-redux/selectors/entities/channels';
import {getAllPosts, getPostsInThread, getRecentPostsChunkInChannel} from 'mattermost-redux/selectors/entities/posts';
import {getAllPinnedPostsIds} from 'mattermost-redux/selectors/entities/search';
import {getFirstQuoteEmbedFromMetadata} from 'mattermost-redux/utils/post_utils';
import {getSelectedPostId} from 'selectors/rhs';
import type {GlobalState} from 'types/store';
import Constants from 'utils/constants';
import {isFeatureEnabled} from 'utils/utils';
import {removePostsFromState} from '../actions/remove_posts_except_channel';
import {TiMePostsLogger} from '../logger';

const logger = TiMePostsLogger.child({
    name: 'deletePostsOnChannelSwitch',
});

const postsIdsInChannelsExceptLogger = TiMePostsLogger.child('postsIdsInChannelsExcept');
const getPostsIdsInLogger = TiMePostsLogger.child('getPostsIdsIn');

function getPostsIdsIn(state: GlobalState, id?: string) {
    if (!id) {
        getPostsIdsInLogger.debug('No thread id passed');
        return [];
    }

    const posts = getPostsInThread(state)[id];

    if (!posts?.length) {
        getPostsIdsInLogger.debug('No posts in thread, skipping...', {
            threadId: id,
        });
        return [];
    }

    return posts;
}

function postsIdsInChannelsExcept(state: GlobalState, exceptChannelId: string) {
    const postsMap = getAllPosts(state);

    const postIdsToLeave = Object.keys(getAllChannels(state))
        .map((channelId) => {
            /**
             * Этот блок содержит последние загруженные посты в канале
             */
            const block = getRecentPostsChunkInChannel(state, channelId);

            postsIdsInChannelsExceptLogger.trace('Found recent block', {
                block,
                channelId,
            });

            if (!block) {
                postsIdsInChannelsExceptLogger.debug('Recent block not found in channel', {
                    channelId,
                });
                return [];
            }

            if (!block.order?.length) {
                postsIdsInChannelsExceptLogger.debug("No posts in channel's recent posts block, skipping...", {
                    channelId,
                });
                return [];
            }

            postsIdsInChannelsExceptLogger.trace('Passing block', {
                blocksOrder: block?.order,
                channelId,
            });

            return block.order;
        })
        .flat();

    const allPosts = Object.values(postsMap);

    postsIdsInChannelsExceptLogger.trace('Defined props', {
        allPosts,
        postIdsToLeave,
        exceptChannelId,
    });

    return allPosts
        .filter((post) => {
            if (postIdsToLeave.includes(post.id)) {
                return false;
            }
            return post.channel_id !== exceptChannelId;
        })
        .map(({id}) => id);
}

function getPostIdsThatAreQuotes(state: GlobalState) {
    const postsMap = getAllPosts(state);
    const allPosts = Object.values(postsMap);

    return allPosts.filter((post) => {
        if (!post.metadata) {
            return false;
        }

        return Boolean(getFirstQuoteEmbedFromMetadata(post.metadata)?.data?.post_id);
    }).map((post) => {
        return getFirstQuoteEmbedFromMetadata(post.metadata)?.data?.post_id;
    });
}

const deletePostsOnChannelSwitchListener = createListenerMiddleware({});

export const deletePostsOnChannelSwitchMiddleware = deletePostsOnChannelSwitchListener.middleware;

function isPostClearancePolicyEnabled(state: GlobalState) {
    return isFeatureEnabled(Constants.PRE_RELEASE_FEATURES.POSTS_CLEAR, state);
}

/**
 * Эта миддлвара запускает экшен удаления постов при сменен канала.
 *
 * В удаляемые посты должны попасть все посты кроме:
 * 1. Постов в открываемом канале
 * 2. Постов в пинах
 * 3. Постов из открытого (видимого) треда
 * 4. Недавние посты в других каналах (обычно это ~30 самых недавних постов в других каналах)
 *
 * Очистка постов не запускается если не включен фича флаг для очистк
 */
deletePostsOnChannelSwitchListener.startListening({
    actionCreator: selectChannel,
    effect: (action, api) => {
        const dispatch = api.dispatch;
        const state = api.getState() as GlobalState;
        const newChannelId = action.payload;

        const enabled = isPostClearancePolicyEnabled(state);

        if (!enabled) {
            logger.debug('Posts clearance policy disabled, skipping...');
            return;
        }

        if (!newChannelId) {
            logger.debug('No channel id passed to select channel, skipping...');
            return;
        }

        const selectedPostId = getSelectedPostId(state);
        const selectedThreadId = getSelectedThreadId(state);
        const postsInSelectedPost = getPostsIdsIn(state, selectedPostId);
        const postsInSelectedThread = getPostsIdsIn(state, selectedThreadId);
        const pinnedPostIds = getAllPinnedPostsIds(state);
        const allPostIds = postsIdsInChannelsExcept(state, newChannelId);
        const postIdsWithQuotes = getPostIdsThatAreQuotes(state);

        logger.trace('Clearance props defined', {
            selectedPostId,
            selectedThreadId,
            postsInSelectedPost,
            postsInSelectedThread,
            pinnedPostIds,
            allPostIds,
            postIdsWithQuotes,
        });

        if (!allPostIds.length) {
            logger.info('No posts to clear, skipping...');
            return;
        }

        const postIdsToRemove = allPostIds.filter((postId) => {
            return (
                postId !== selectedPostId &&
                postId !== selectedThreadId &&
                !postIdsWithQuotes.includes(postId) &&
                !postsInSelectedPost.includes(postId) &&
                !postsInSelectedThread.includes(postId) &&
                !pinnedPostIds.includes(postId)
            );
        });

        if (!postIdsToRemove.length) {
            logger.info('No posts to clear after filtering, skipping...');
            return;
        }

        logger.info(`${postIdsToRemove.length} posts scheduled for clearance`);
        logger.trace('Posts are scheduled for clearance', {postIds: postIdsToRemove, newChannelId});

        dispatch(
            removePostsFromState({
                postIds: postIdsToRemove,
                channelId: newChannelId,
            }),
        );
    },
});
