import {useCallback, useContext, useEffect, useRef, useState} from 'react';

import {addQuery} from '@tinkoff/url';

import {AutoUpdateContext} from 'features/autoupdate/context';

import {type UpdateEvent, UpdateEventType, UpdateType} from 'features/autoupdate/types';
import {useStatist} from 'packages/statist';
import {hasDesktopUpdates, hasWebUpdates} from 'features/autoupdate/utils';

export enum BannerState {
    Start,
    Downloading,
    Error,
    Retrying,
}

export const useBannerState = () => {
    const {hasUpdates, updateType, updateState} = useContext(AutoUpdateContext);

    const [bannerState, setBannerState] = useState(updateState ? BannerState.Downloading : BannerState.Start);
    const [downloadState, setDownloadState] = useState(updateState);

    const {sendToStatist} = useStatist();

    const retryDelayTimeout = useRef<NodeJS.Timeout>();
    const retryDuration = useRef<Promise<void>>();

    const onUpdateEvent = useCallback((event: UpdateEvent) => {
        const handleWithRetryTimeouts = (callback: () => void) => {
            if (retryDelayTimeout.current) {
                clearTimeout(retryDelayTimeout.current);
                retryDelayTimeout.current = undefined;
            }

            if (retryDuration.current) {
                retryDuration.current.then(callback);
            } else {
                callback();
            }
        };

        switch (event.type) {
        case UpdateEventType.InProgress:
            handleWithRetryTimeouts(() => {
                setDownloadState(event.state);
                setBannerState(BannerState.Downloading);
            });
            break;
        case UpdateEventType.Error:
            handleWithRetryTimeouts(() => {
                setBannerState(BannerState.Error);
                setDownloadState(undefined);
            });
            break;
        }
    }, []);

    useEffect(() => {
        return window.internalAPI?.onUpdateEvent?.(onUpdateEvent);
    }, [onUpdateEvent]);

    useEffect(() => {
        if (hasUpdates) {
            sendToStatist('app.update.notify', {
                electronUpdate: hasDesktopUpdates(updateType),
                appUpdate: hasWebUpdates(updateType),
            });
        }
    }, [hasUpdates, updateType, sendToStatist]);

    useEffect(() => {
        if (bannerState === BannerState.Error) {
            sendToStatist('app.update.retry');
        }
    }, [bannerState, sendToStatist]);

    const startUpdate = useCallback((retry?: boolean) => {
        if (!hasUpdates || !updateType) {
            return;
        }

        if (retry) {
            sendToStatist('app.update.retryTap');
        } else {
            sendToStatist('app.update.tap', {
                electronUpdate: hasDesktopUpdates(updateType),
                appUpdate: hasWebUpdates(updateType),
                sourceTap: 'sidebar',
            });
        }

        if (updateType === UpdateType.Web) {
            window.location.href = addQuery(window.location.href, {
                cacheBust: Date.now().toString(),
            }).toString();
            return;
        }

        window.internalAPI?.startUpdate?.();
    }, [hasUpdates, updateType, sendToStatist]);

    const handleClick = useCallback(() => {
        if (updateType === UpdateType.Web) {
            startUpdate();
            return;
        }

        switch (bannerState) {
        case BannerState.Downloading:
        case BannerState.Retrying:
            break;
        case BannerState.Start:
            startUpdate();
            setBannerState(BannerState.Downloading);
            break;
        case BannerState.Error:
            if (retryDelayTimeout.current) {
                break;
            }

            startUpdate(true);
            retryDelayTimeout.current = setTimeout(() => {
                setBannerState(BannerState.Retrying);
                retryDuration.current = new Promise((res) => setTimeout(res, 500));
                retryDelayTimeout.current = undefined;
            }, 300);
        }
    }, [bannerState, startUpdate, updateType]);

    return {
        hasUpdates,
        updateType,
        bannerState,
        downloadState,
        handleClick,
    };
};
