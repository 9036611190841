import {DialogOverlay} from './dialog-overlay';
import {PopoverOverlay} from './popover-overlay';

export function PopoverDialogOverlay({children, ...props}: React.ComponentProps<typeof PopoverOverlay>) {
    return (
        <PopoverOverlay {...props}>
            <DialogOverlay>{children}</DialogOverlay>
        </PopoverOverlay>
    );
}
