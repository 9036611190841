import classNames from 'classnames';

import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {AutoHeightAnimation} from './auto_height_animation';

import styles from './banner.module.css';
type Props = {
    title: string;
    description: string;
};

export const Message = ({title, description}: Props) => {
    const [bodyMTrue, bodyS] = useTypography([{
        size: TypographySize.BodyMTrue,
        noDefaultColor: true,
    }, {
        size: TypographySize.BodyS,
        noDefaultColor: true,
    }]);

    return (
        <AutoHeightAnimation duration={150} easing='cubic-bezier(0.2, 0, 0.4, 0.9)'>
            <div className={classNames(bodyMTrue, styles.title)}>{title}</div>
            <div className={classNames(bodyS, styles.description)}>{description}</div>
        </AutoHeightAnimation>
    );
};
