export enum UpdateType {
    Web = 'web',
    Desktop = 'desktop',
    WebAndDesktop = 'web_and_desktop',
}

export type ProgressInfo = {
    transferred: number;
    percent: number;
}

export type DesktopCheckResult = {
    type: 'update_exist';
} | {
    type: 'update_in_progress';
    state: ProgressInfo;
} | {
    type: 'no_updates';
    nextCheckTimestamp: number;
};

export enum UpdateEventType {
    ForceCheck = 'force_check',
    WebCheck = 'web_check',
    InProgress = 'update_in_progress',
    Downloaded = 'update_downloaded',
    Error = 'update_error',
    StartUpdate = 'start_update',
    UpdateExist = 'update_exist',
}

export type UpdateEvent = {
    type: UpdateEventType.ForceCheck;
} | {
    type: UpdateEventType.InProgress;
    state: ProgressInfo;
} | {
    type: UpdateEventType.Downloaded;
} | {
    type: UpdateEventType.Error;
    reason: string;
} | {
    type: UpdateEventType.WebCheck;
} | {
    type: UpdateEventType.StartUpdate;
} | {
    type: UpdateEventType.UpdateExist;
};
