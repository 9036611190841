import {createContext, useCallback, useEffect, useMemo, useRef, useState, type PropsWithChildren} from 'react';

import {sendToStatist} from 'packages/statist';

import {reportErrorToSentry} from 'utils/sentry';

import {isDesktopApp} from 'utils/user_agent';

import {getWebappVersion} from './api';
import {type UpdateEvent, type ProgressInfo, UpdateType, UpdateEventType} from './types';
import {hasDesktopUpdates, hasWebUpdates} from './utils';

const currentVersion = __RELEASE_VERSION__;

const isDesktop = isDesktopApp();

const NEXT_CHECK_INTERVAL = 4 * 60 * 60 * 1000; // 4 hours

const checkForWebUpdates = async () => {
    try {
        const newVersion = await getWebappVersion();
        const hasUpdates = newVersion !== currentVersion;

        window.internalAPI?.notifyWebUpdate?.(hasUpdates);

        return {hasUpdates};
    } catch (error) {
        reportErrorToSentry({
            message: 'Error when trying to check web updates',
            error,
        });
        return {
            hasUpdates: false,
            error: error instanceof Error ? error.toString() : '',
        };
    }
};

export type AutoUpdateContext = {
    hasUpdates: boolean;
    updateType?: UpdateType;
    updateState?: ProgressInfo;
};

enum UpdateCheckEventSource {
    OnStart,
    InBackground,
    FromDesktop
}
type SendUpdateCheckEventArgs = {
    updateExist: boolean;
    source: UpdateCheckEventSource;
    error?: string;
};
const sendUpdateCheckEvent = ({updateExist, source, error}: SendUpdateCheckEventArgs) => {
    const params: Pick<SendUpdateCheckEventArgs, 'updateExist' | 'error'> = {
        updateExist,
    };

    if (error) {
        params.error = error;
    }

    switch (source) {
    case UpdateCheckEventSource.OnStart:
        sendToStatist('app.update.checkStart', params);
        break;
    case UpdateCheckEventSource.InBackground:
        sendToStatist('app.update.checkBackground', params);
        break;
    case UpdateCheckEventSource.FromDesktop:
        sendToStatist('app.update.checkTap', params);
    }
};

export const AutoUpdateContext = createContext<AutoUpdateContext>({hasUpdates: false});

export const AutoUpdateContextProvider = ({children}: PropsWithChildren) => {
    const [hasUpdates, setHasUpdates] = useState(false);
    const [updateType, setUpdateType] = useState<UpdateType>();
    const [updateState, setUpdateState] = useState<ProgressInfo>();
    const timeoutId = useRef<NodeJS.Timer>();

    const checkForUpdates = useCallback(async (isInitial = false, isForced = false) => {
        try {
            let source = UpdateCheckEventSource.InBackground;

            if (isInitial) {
                source = UpdateCheckEventSource.OnStart;
            } else if (isForced) {
                source = UpdateCheckEventSource.FromDesktop;
            }

            if (!isDesktop) {
                const checkResult = await checkForWebUpdates();

                setHasUpdates(checkResult.hasUpdates);
                setUpdateType(UpdateType.Web);

                sendUpdateCheckEvent({
                    source,
                    updateExist: checkResult.hasUpdates,
                    error: checkResult.error,
                });
                return;
            }

            const [webCheckResult, desktopCheckResult] =
                await Promise.all([checkForWebUpdates(), window.internalAPI?.checkForUpdate?.(isForced)]);

            const hasWebUpdates = webCheckResult.hasUpdates;
            const hasDesktopUpdates = desktopCheckResult && desktopCheckResult.type !== 'no_updates';

            sendUpdateCheckEvent({
                source,
                updateExist: Boolean(hasWebUpdates || hasDesktopUpdates),
                error: webCheckResult.error,
            });

            if (hasDesktopUpdates) {
                setHasUpdates(true);

                if (hasWebUpdates) {
                    setUpdateType(UpdateType.WebAndDesktop);
                } else {
                    setUpdateType(UpdateType.Desktop);
                }

                const state = desktopCheckResult.type === 'update_in_progress' ? desktopCheckResult.state : undefined;
                setUpdateState(state);
                return;
            }

            setHasUpdates(hasWebUpdates);

            if (hasWebUpdates) {
                setUpdateType(UpdateType.Web);
            } else {
                setUpdateState(undefined);
            }

            if (desktopCheckResult?.type === 'no_updates') {
                return desktopCheckResult.nextCheckTimestamp;
            }
        } catch (error) {
            reportErrorToSentry({
                message: 'Error when trying to check updates',
                error,
            });
        }
    }, []);

    const checkAndScheduleNext = useCallback((isInitial = false, isForced = false) => {
        checkForUpdates(isInitial, isForced).then((nextCheckTimestamp) => {
            const interval = nextCheckTimestamp ? nextCheckTimestamp - Date.now() : NEXT_CHECK_INTERVAL;
            timeoutId.current = setTimeout(checkAndScheduleNext, interval);
        });
    }, [checkForUpdates]);

    useEffect(() => {
        checkAndScheduleNext(true);

        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [checkAndScheduleNext]);

    useEffect(() => {
        return window.internalAPI?.onUpdateEvent?.((event: UpdateEvent) => {
            switch (event.type) {
            case UpdateEventType.UpdateExist:
                setHasUpdates(true);

                if (hasDesktopUpdates(updateType)) {
                    break;
                }

                setUpdateType(updateType === UpdateType.Web ? UpdateType.WebAndDesktop : UpdateType.Desktop);
                break;
            case UpdateEventType.WebCheck:
                window.internalAPI?.notifyWebUpdate?.(hasUpdates);
                break;
            case UpdateEventType.ForceCheck:
                if (timeoutId.current) {
                    clearTimeout(timeoutId.current);
                }
                checkAndScheduleNext(false, true);
                break;
            case UpdateEventType.Error:
                sendToStatist('app.update.downloadFinished', {success: false, errorType: event.reason});
                break;
            case UpdateEventType.Downloaded:
                sendToStatist('app.update.downloadFinished', {success: true});
                break;
            case UpdateEventType.StartUpdate:
                sendToStatist('app.update.tap', {
                    electronUpdate: hasDesktopUpdates(updateType),
                    appUpdate: hasWebUpdates(updateType),
                    sourceTap: 'options',
                });
            }
        });
    }, [checkAndScheduleNext, hasUpdates, updateType]);

    const contextValue = useMemo(() => ({
        hasUpdates, updateType, updateState,
    }), [hasUpdates, updateState, updateType]);

    return (
        <AutoUpdateContext.Provider value={contextValue}>
            {children}
        </AutoUpdateContext.Provider>
    );
};
