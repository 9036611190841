import {useIntl} from 'react-intl';

import {fileSizeToString} from '@time-webkit/all/utils/attachments';

import {UpdateType} from 'features/autoupdate/types';

import {BannerState} from './use_banner_state';

type Params = {
    type?: UpdateType;
    state: BannerState;
    download?: {
        transferred: number;
        percent: number;
    };
};

export const useTexts = ({type, state, download}: Params) => {
    const {formatMessage} = useIntl();

    const title = formatMessage({
        id: 'autoupdate.banner.title',
        defaultMessage: 'A new version of Time is available',
    });
    const description = type === UpdateType.Web ? formatMessage({
        id: 'autoupdate.banner.description.web',
        defaultMessage: 'The update will take about five seconds. Your drafts will be saved',
    }) : formatMessage({
        id: 'autoupdate.banner.description.desktop',
        defaultMessage: 'The download will take about two minutes. After that, the application will restart and your drafts will be saved',
    });

    switch (state) {
    case BannerState.Start:
        return {
            title,
            description,
            button: formatMessage({
                id: 'autoupdate.banner.button.update',
                defaultMessage: 'Update',
            }),
        };
    case BannerState.Downloading:
        return {
            title,
            description,
            button: formatMessage({
                id: 'autoupdate.banner.button.downloading',
                defaultMessage: 'Downloading update',
            }),
            tooltip: download ? formatMessage({
                id: 'autoupdate.banner.tooltip',
                defaultMessage: 'Downloaded {transferred} - {percent}%',
            }, {
                transferred: fileSizeToString(download.transferred),
                percent: Math.floor(download.percent),
            }) : undefined,
        };
    case BannerState.Error:
    case BannerState.Retrying:
        return {
            title: formatMessage({
                id: 'autoupdate.banner.error.title',
                defaultMessage: "Couldn't update the app",
            }),
            description: formatMessage({
                id: 'autoupdate.banner.error.description',
                defaultMessage: 'Try again or contact support team',
            }),
            button: state === BannerState.Error ? formatMessage({
                id: 'autoupdate.banner.button.repeat',
                defaultMessage: 'Repeat',
            }) : '',
        };
    }
};
