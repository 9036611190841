import {type SVGProps, useId, forwardRef} from 'react';

export const RocketIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    const clipPathId = useId();

    return (
        <svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props} ref={ref}>
            <g clipPath={`url(#${clipPathId})`}>
                <path d='M8.4 14.77a.333.333 0 0 1-.61-.007l-.652-1.534a.34.34 0 0 1 .189-.448 13.628 13.628 0 0 0 1.61-.774c.293-.165.652.147.513.452L8.4 14.77ZM3.22 8.673a.34.34 0 0 1-.448.19L1.238 8.21a.333.333 0 0 1-.007-.61l2.31-1.05c.306-.138.617.22.453.513-.29.517-.551 1.054-.774 1.61Zm8.927-5.34c.187 0 .354 0 .493.034.114.926-.013 2.833-2.2 5.02a11.29 11.29 0 0 1-4.006 2.593L5 9.58c.613-1.54 1.486-2.893 2.613-4.02 1.84-1.84 3.48-2.227 4.533-2.227Zm0-1.333c-1.32 0-3.32.46-5.48 2.62-1.46 1.46-2.333 3.067-2.9 4.473-.186.5-.06 1.047.307 1.42l1.42 1.414c.253.253.593.406.947.406.153 0 .313-.04.466-.1a12.733 12.733 0 0 0 4.473-2.9c2.887-2.886 2.738-5.496 2.509-6.563a.85.85 0 0 0-.66-.66A5.16 5.16 0 0 0 12.147 2Zm-3.12 4.973a1.336 1.336 0 0 1 0-1.886c.52-.52 1.367-.52 1.887 0 .513.52.52 1.366 0 1.886-.52.52-1.367.52-1.887 0Z' fill='currentColor'/>
            </g>
            <defs>
                <clipPath id={clipPathId}>
                    <rect width={props.width} height={props.height} fill='currentColor'/>
                </clipPath>
            </defs>
        </svg>
    );
});
