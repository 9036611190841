import {Spinner} from '@time-webkit/all/atoms/spinner';
import {RefreshIcon} from '@time-webkit/all/icons/refresh';
import {RocketIcon} from '@time-webkit/all/icons/rocket';
import {type ProgressInfo} from 'features/autoupdate/types';
import {Loader} from '../loader';

import {BannerState} from './use_banner_state';

type Props = {
    bannerState: BannerState;
    downloadState?: ProgressInfo | undefined;
};

export const Icon = ({bannerState, downloadState}: Props) => {
    switch (bannerState) {
    case BannerState.Start:
        return <RocketIcon width={16} height={16}/>;
    case BannerState.Downloading:
        return <Loader percent={downloadState?.percent} />;
    case BannerState.Error:
        return <RefreshIcon width={16} height={16} />;
    case BannerState.Retrying:
        return <Spinner size={16} color='currentColor' />;
    }
};
