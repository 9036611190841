import {UpdateButton} from '../update_button';

import {useTexts} from './use_texts';
import {Icon} from './icon';

import styles from './banner.module.css';
import {Message} from './message';
import {useBannerState} from './use_banner_state';

export const AutoUpdateBanner = () => {
    const {hasUpdates, updateType, bannerState, downloadState, handleClick} = useBannerState();
    const texts = useTexts({
        type: updateType,
        state: bannerState,
        download: downloadState,
    });

    if (!hasUpdates) {
        return null;
    }

    return (
        <div className={styles.banner} data-test-id='auto-update-banner'>
            <Message title={texts.title} description={texts.description} />
            <UpdateButton
                className={styles.button}
                text={texts.button}
                icon={<Icon bannerState={bannerState} downloadState={downloadState}/>}
                tooltip={texts.tooltip}
                onClick={handleClick}
            />
        </div>
    );
};
