import {useRef, useState, useEffect, type ComponentProps, type ReactNode} from 'react';

import AnimateHeight, {type Height} from 'react-animate-height';

type Props = {
    children: ReactNode;
} & Omit<ComponentProps<typeof AnimateHeight>, 'height' | 'contentRef'>;

export const AutoHeightAnimation = ({children, ...props}: Props) => {
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = contentDiv.current;

        if (!element) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    return (
        <AnimateHeight
            {...props}
            height={height}
            contentRef={contentDiv}
        >
            {children}
        </AnimateHeight>
    );
};
