import {type ComponentProps, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useOverlayTriggerState} from '@react-stately/overlays';
import classnames from 'classnames';

import {Avatar} from '@time-webkit/all/atoms/avatar';

import type {UserStatus} from 'packages/ui-kit/src/types/general';

import ProfilePopover from 'components/profile_popover';
import StatusIcon from 'components/status_icon';
import {getUserById} from 'mattermost-redux/selectors/entities/common';
import {getDisplayNameByUser} from 'utils/utils';

import {PopoverDialogOverlay, type PopoverOverlay} from '@time-webkit/all/molecules/popover';

import {type GlobalState} from 'types/store';

import styles from './profile_picture.module.css';

type Props = {

    /**
     * id родительского элемента, нужно указать, если UserProfile располагается внутри модалки
     */
    appendId?: string;
    userId?: string;
    showProfilePopover?: boolean;
    hasMention?: boolean;
    isEmoji?: boolean;
    isRHS?: boolean;
    profileSrc?: string;
    size?: ComponentProps<typeof Avatar>['size'];
    src: string;
    status?: UserStatus;
    channelId?: string;
    username?: string;
    wrapperClass?: string;
    overwriteIcon?: string;
    overwriteName?: string;
    statusClass?: string;
    isBot?: boolean;
    fromWebhook?: boolean;
    fromAutoResponder?: boolean;
    popoverPlacement?: ComponentProps<typeof PopoverOverlay>['placement'];
    onProfilePopoverOpen?: () => void;
    onProfilePopoverClose?: () => void;
    testId?: string;
};

export const ProfilePicture = ({
    appendId,
    userId,
    showProfilePopover,
    hasMention = false,
    isEmoji = false,
    isRHS = false,
    profileSrc: defaultProfileSrc,
    size = 36,
    src,
    status,
    channelId,
    username: defaultUsername,
    wrapperClass = '',
    overwriteIcon,
    overwriteName,
    statusClass,
    isBot,
    fromWebhook,
    fromAutoResponder,
    popoverPlacement = 'right',
    onProfilePopoverOpen,
    onProfilePopoverClose,
    testId,
}: Props) => {
    const triggerRef = useRef<HTMLButtonElement>(null);

    const user = useSelector((state: GlobalState) => getUserById(state, userId ?? ''));
    const username = useSelector((state: GlobalState) => getDisplayNameByUser(state, user)) || defaultUsername;

    const popoverState = useOverlayTriggerState({
        onOpenChange: (isOpen) => {
            if (isOpen) {
                onProfilePopoverClose?.();
            } else {
                onProfilePopoverOpen?.();
            }
        },
    });

    const profileIconClass = `profile-icon profile-icon--${size} ${isEmoji ? 'emoji' : ''}`;

    const avatar = (
        <span className={profileIconClass} data-testid={testId}>
            {src && (
                <Avatar
                    username={username}
                    size={size}
                    src={src}
                />
            )}
        </span>
    );

    // profileSrc will, if possible, be the original user profile picture even if the icon
    // for the post is overriden, so that the popup shows the user identity
    const profileSrc = (typeof defaultProfileSrc === 'string' && defaultProfileSrc !== '') ? defaultProfileSrc : src;

    const hideStatus = isBot || fromAutoResponder || fromWebhook;
    const statusIconSizeClass = `status-icon-${size}`;

    if (showProfilePopover && userId) {
        return (
            <>
                <button
                    ref={triggerRef}
                    className={`status-wrapper style--none ${wrapperClass}`}
                    tabIndex={-1}
                    onClick={popoverState.toggle}
                >

                    {avatar}
                    <StatusIcon className={statusIconSizeClass} status={status}/>
                </button>
                <PopoverDialogOverlay
                    state={popoverState}
                    placement={popoverPlacement}
                    triggerNode={triggerRef.current}
                >
                    <ProfilePopover
                        appendId={appendId}
                        userId={userId}
                        src={profileSrc}
                        className={styles.popover}
                        isRHS={isRHS}
                        hide={popoverState.close}
                        channelId={channelId}
                        hasMention={hasMention}
                        overwriteIcon={overwriteIcon}
                        overwriteName={overwriteName}
                        fromWebhook={fromWebhook}
                        hideStatus={hideStatus}
                    />
                </PopoverDialogOverlay>
            </>
        );
    }

    return (
        <span className={`status-wrapper style--none ${wrapperClass}`}>
            {avatar}
            <StatusIcon
                status={status}
                className={classnames(statusClass, statusIconSizeClass)}
            />
        </span>
    );
};
