import styles from './loader.module.css';

const STROKE_DASH_ARRAY = 38;

export const Loader = ({percent = 0}: {percent?: number}) => {
    const strokeDashOffset = STROKE_DASH_ARRAY * (1 - (percent / 100));

    return (
        <div className={styles.loader}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
                <path
                    className={styles.path}
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeWidth='1.2'
                    strokeDasharray={STROKE_DASH_ARRAY}
                    strokeDashoffset={strokeDashOffset}
                    d='M7.999 1.934a6.066 6.066 0 1 1 0 12.132 6.066 6.066 0 0 1 0-12.132Z'
                />
                <path
                    fill='currentColor'
                    fillRule='evenodd'
                    d='M7.987 5.332a.444.444 0 0 0-.445.444v2.667l-1.12-1.07a.448.448 0 0 0-.623.646l1.873 1.824c.174.17.452.168.624-.004l1.82-1.82a.446.446 0 0 0-.631-.631L8.43 8.443l.001-2.666a.444.444 0 0 0-.444-.445Z'
                    clipRule='evenodd'
                />
            </svg>
        </div>
    );
};
